"use client";
import React from "react";
import dynamic from "next/dynamic";
import { MapProps } from "modules/common/components/map/Map";
import MapSkeleton from "modules/common/components/skeletons/map/MapSkeleton";

const DynamicFindStoresMap = ({
  heightDVH,
  enableSearchBar = true,
}: MapProps) => {
  const Map = React.useMemo(
    () =>
      dynamic(() => import("modules/common/components/map/Map"), {
        loading: () => (
          <MapSkeleton
            enableSearchBar={enableSearchBar}
            heightDVH={heightDVH}
          />
        ),
        // loading: () => <Loader sx={{ minHeight: `${heightDVH}dvh` }} />,
        ssr: false,
      }),
    [
      /* list variables which should trigger a re-render here */
    ]
  );
  return <Map heightDVH={heightDVH} enableSearchBar={enableSearchBar} />;
};

export default DynamicFindStoresMap;
