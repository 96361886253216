import { useQuery } from "@tanstack/react-query";
import { LatLngTuple } from "leaflet";
import { useMemo } from "react";
import { useFetchWrapper } from "modules/common/hooks/useFetchWrapper";
import { API_ROUTES } from "utils/api-route.constants";
import { LGS } from "modules/lgs/model/lgs";
import { useSnackbar } from "notistack";
import { useRecoilValue } from "recoil";
import { MapSearchResultCity } from "modules/common/components/map/recoil/Map.state";

const useWotcLGS = (coords: LatLngTuple) => {
  const fetch = useFetchWrapper();
  const { enqueueSnackbar } = useSnackbar();
  const searchCity = useRecoilValue(MapSearchResultCity);
  const { isSuccess, isInitialLoading, isFetching, isPending, data } = useQuery(
    {
      queryKey: ["lgs", coords],
      queryFn: () => fetchShopData(coords),
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    }
  );

  const shopLocations = useMemo(
    () => data?.map((shop) => [shop.latitude, shop.longitude]),
    [data]
  );

  return {
    data,
    isInitialLoading,
    isPending,
    isFetching,
    isSuccess,
    shopLocations,
  };

  async function fetchShopData(coords: LatLngTuple): Promise<LGS[]> {
    const [lng, lat] = coords;
    const params = `?lat=${lat}&lng=${lng}`;
    const response: LGS[] = await fetch.get(API_ROUTES.LGS.GET + params, null);
    if (response.length === 0) {
      enqueueSnackbar(`No stores found in ${searchCity}`, {
        variant: "warning",
      });
    }
    return response;
  }
};

export default useWotcLGS;
