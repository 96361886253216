import React from "react";
import { Box, Skeleton } from "@mui/material";

const MapSkeleton = ({ heightDVH = 45, enableSearchBar = true }) => {
  return (
    <Box
      borderRadius={5}
      sx={{
        width: "100%",
      }}
      position={"relative"}
    >
      {enableSearchBar && (
        <Box
          position={"absolute"}
          left={0}
          right={0}
          zIndex={999}
          sx={{
            width: {
              xs: "90%",
              md: "50%",
              lg: "40%",
              xl: "30%",
            },
            top: {
              xs: 15,
              md: 30,
            },
          }}
          mx={"auto"}
        >
          <Skeleton variant="rectangular" height={40} />
        </Box>
      )}
      <Skeleton
        variant="rounded"
        animation={"wave"}
        sx={{
          borderRadius: "4px",
          width: "100%",
          height: `${heightDVH}dvh`,
          backgroundColor: "background.paper",
        }}
      />
    </Box>
  );
};

export default MapSkeleton;
