"use client";
import { useCallback, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { RawResult } from "leaflet-geosearch/lib/providers/openStreetMapProvider";
import { SearchResult } from "leaflet-geosearch/lib/providers/provider";
import { Clear, LocationOn } from "@mui/icons-material";
import { Box, Grid, SxProps, Theme, useMediaQuery } from "@mui/material";

import debounce from "lodash/debounce";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  MapSearchResultCity,
  MapSearchResultCoords,
} from "modules/common/components/map/recoil/Map.state";
import useTheme from "@mui/material/styles/useTheme";
import useWotcLGS from "modules/common/components/map/hooks/useWotcLGS";

export type MapSearchAutocompleteProps = {
  sx?: SxProps;
};
export default function MapSearchAutocomplete({
  sx,
}: MapSearchAutocompleteProps) {
  const [options, setOptions] = useState<SearchResult<RawResult>[]>([]);
  const [value, setValue] = useState<SearchResult<RawResult> | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchCoords, setSearchCoords] = useRecoilState(MapSearchResultCoords);
  const setSearchCity = useSetRecoilState(MapSearchResultCity);
  const theme = useTheme();
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const formattedLabel = useCallback((label: string) => {
    return `${label.split(",")[0]} - ${label.split(",").slice(-1)}`;
  }, []);
  const { isFetching, isInitialLoading, data } = useWotcLGS(searchCoords);
  const provider = new OpenStreetMapProvider();

  const handleSearch = useCallback(async (searchInput: string) => {
    setLoading(true);
    const results = await provider.search({ query: searchInput });
    const filteredResults = results.map((result) => ({
      ...result,
      label: formattedLabel(result.label),
    }));
    const cities = filteredResults.filter((value) => value.raw.type === "city");
    const administrative = filteredResults.filter(
      (value) => value.raw.type === "administrative"
    );
    setOptions(cities.length === 0 ? administrative : cities);
    setLoading(false);
  }, []);

  const debouncedSearch = debounce(handleSearch, 500);

  return (
    <Autocomplete
      id="map-search-autocomplete"
      size={"small"}
      options={options}
      value={value}
      clearIcon={<Clear />}
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: "4px",
        padding: 1,
        ...sx,
      }}
      isOptionEqualToValue={(option, value) =>
        option.x === value.x && option.y === value.y
      }
      filterOptions={(x: SearchResult<RawResult>[]) => x}
      loading={loading}
      noOptionsText={
        !isFetching && data && data?.length === 0
          ? "No stores found"
          : "Search for a city"
      }
      autoComplete
      includeInputInList
      filterSelectedOptions
      onInputChange={async (event, newInputValue) => {
        debouncedSearch(newInputValue);
      }}
      onChange={(event: any, newValue: SearchResult<RawResult> | null) => {
        setOptions(newValue ? [newValue, ...options] : options);

        setValue(newValue);
        if (newValue) {
          setSearchCoords(() => [newValue?.x, newValue?.y]);
          setSearchCity(newValue.label);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            mdDown
              ? "Where do you play magic?"
              : "In which city do you play magic?"
          }
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.raw.place_id}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOn sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                <Box component="span">{option.label}</Box>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
