import { atom } from "recoil";
import { LatLngTuple } from "leaflet";

type MapCenter = LatLngTuple;

export const DefaultMapCenter = atom<MapCenter>({
  key: "MapCenter",
  default: [42.6977028, 23.3217359],
});
export const DefaultZoom = atom<number>({
  key: "defaultZoom",
  default: 13,
});

export const MapSearchResultCoords = atom<LatLngTuple>({
  key: "MapSearchResultCoords",
  default: [23.3217359, 42.6977028],
});

export const MapSearchResultCity = atom<string>({
  key: "MapSearchResultCity",
  default: "Sofia - Bulgaria",
});
